.container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 268px;
  border-radius: 8px;
  box-shadow: 0 6px 16px -6px rgba(12, 37, 76, 10%);
}

.logo {
  width: 134px;
  height: 48px;
  position: relative;
}

.title {
  color: #fff;
}

.btnContainer {
  --btn-size-md-padding: 14px 16px;
}

.iconSize {
  --btn-icon-size: 20px;
  --btn-icon-spacing: 10px;
}

@media screen and (--desktop) {
  .container {
    height: 148px;
    border-radius: 12px;
  }

  .logo {
    width: 156px;
    height: 56px;
  }

  .title {
    --typography-heading-6-font-size: var(--typography-heading-5-font-size);
    --typography-heading-6-line-height: var(--typography-heading-5-line-height);
    --typography-heading-6-letter-spacing: var(
      --typography-heading-5-letter-spacing
    );
  }

  .description {
    --typography-body-regular-font-size: var(--typography-body-large-font-size);
  }

  .btnContainer {
    width: 200px;

    --btn-size-md-padding: 16px 24px;
    --btn-font-size: 18px;
    --btn-line-height: 24px;
    --btn-size-md-height: 56px;
  }

  .iconSize {
    --btn-icon-size: 24px;
    --btn-icon-spacing: 12px;
  }
}

@media screen and (--desktop-m) {
  .title {
    --typography-heading-6-font-size: var(--typography-heading-4-font-size);
    --typography-heading-6-line-height: var(--typography-heading-4-line-height);
    --typography-heading-6-letter-spacing: var(
      --typography-heading-4-letter-spacing
    );
  }
}
