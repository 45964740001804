.container {
  padding: 24px;
}

.subscribeButton {
  border-radius: 4px;
}

@media screen and (--desktop) {
  .input {
    width: 100%;
  }

  .subscribeButton {
    width: 148px;
  }

  .inputButtonContainer {
    width: 100%;
  }
}
