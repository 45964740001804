.accordionItem[aria-expanded='false'] {
  --accordion-title-color-text: var(--color-secondary-muted);
}

.title {
  line-height: 0;
}

.content_inner {
  color: var(--color-typography-body);
  font-family: var(--typography-button-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  padding: 0;
  padding-bottom: 40px;
}
