.accordionIcon {
  color: white;

  --accordion-expand-icon-size: 32px;
}

.footerLinksContainer {
  gap: var(--footer-links-gap);
}

.footerLinksList {
  row-gap: var(--footer-links-list-gap);
}
